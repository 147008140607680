@import "~bootstrap/scss/bootstrap";
@import "~rfs/scss/rfs";

html {
    position: relative;
    min-height: 100%;
}

body {
    background-color: #efebce;
    margin-bottom: 60px; /* Margin bottom by footer height */
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #cfbe98;
}

.navbar {
  background-color: #000000;
}

.property-container{
  overflow: auto;
  background-color: #f8e7b7;
  margin-bottom: 8px;
  border-radius: 0 0 4px 4px;

}

.property {
  background-color: #9C8970;
  padding: 5px 5px 5px 10px;
  clear: both;
  display: block;
  margin-bottom: 5px;
  border-radius: 4px 4px  0 0 ;
}

.subproperty {
  background-color: #F9F3C9;
  float:left;
  display: block;
  padding: 5px;
  margin: 11px 4px;
  text-align: center;
}

.subpropertyitems {
  float:left;
  display: block;
  margin: 11px 4px;
}

.subproperty .title {
  font-weight: bold;
}

.big {font-size: 8em; font-weight: lighter; line-height: 0.8em;}
.underbig {font-size: 1.5em}

.triangular {
  text-align: center;
  line-height: 0.8rem;
  padding: 0;
  margin: 0;
}

.explain {
  text-align: center;
  padding: 0;
  margin: 4px 0 0 0;
}


//p.pentagonal {
//  text-align: center;
//  line-height: 0.8rem;
//}
//
//div.pentagonal {
//  display: block;
//  padding: 0 1em;
//  margin: 0 1em;
//}